<style>
.v-application--wrap {
  flex: 1 1 auto;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  min-height: 115vh;
  max-width: 100%;
  position: relative;
}
</style>
<template>
  <div>
    <v-footer>
      <v-card class="py-10" width="100%">
        <v-card-text class="px-0">
          <v-container>
            <v-row :class="[$vuetify.breakpoint.smAndUp ? 'pt-5' : '']">
              <v-col cols="12" sm="12" class="px-0">
                <v-row justify="center">
                  <v-col
                    cols="12"
                    sm="12"
                    :class="[$vuetify.breakpoint.xsOnly ? 'pl-6' : '']"
                  >
                    <div class="footerLinks mt-8" style="margin-left: 3%">
                      <div
                        style="
                          font-weight: 700;
                          font-size: 26px;
                          font-family: Roboto Slab;
                          line-height: 30px;
                          letter-spacing: 1px;
                        "
                      >
                        Welcome To Support!
                      </div>
                      <br />
                      <h4>Need help? We're here for you.</h4>
                      <br /><br />
                      <!--   <div class="infoColumn">
                        <img
                          src="@/assets/publicDonationPage/contactLogo.svg"
                          alt=""
                          class="contactImg"
                          :style="
                            $vuetify.breakpoint.xsOnly ? 'max-width:30px;' : ''
                          "
                        />
                        <div
                          style="margin-top: auto; margin-bottom: auto"
                          class="borderLinksContent ml-6"
                        >
                          <a href="tel:(201)-271-3600" style="color: black"
                            >(201)-271-3600</a
                          >
                        </div>
                      </div>-->
                      <div class="infoColumn">
                        <img
                          src="@/assets/publicDonationPage/message.svg"
                          alt=""
                          class="contactImg"
                          :style="
                            $vuetify.breakpoint.xsOnly ? 'max-width:30px;' : ''
                          "
                        />
                        <div
                          style="margin-top: auto; margin-bottom: auto"
                          class="borderLinksContent ml-6"
                        >
                          <a
                            href="mailto:support@harlemwizardsinabox.com"
                            style="color: black"
                          >
                            support@harlemwizardsinabox.com
                          </a>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-footer>
  </div>
</template>

<script>
export default {
  name: "NeedHelp",
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped>
* {
  font-family: Lato;
}
.appBarTitle {
  font-size: 22px;
  font-weight: 700;
  padding-top: 20px;
}
.v-btn >>> span {
  color: #2c1963;
  font-weight: 700;
  font-size: 17px;
  font-family: Roboto Slab;
}
.footerParagraph {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: white;
  line-height: 108.5%;
  margin-top: 13px;
}
.footerIcon {
  margin-left: 30px;
}
.footerText {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 161.5%;
  /* or 45px */
  color: #ffffff;
  margin-top: 40px;
  margin-bottom: 40px;
}
.vl {
  border-left: 2px solid #a691e4;
  height: 300px;
  margin-top: 20px;
}
.hl {
  border-top: 2px solid #a691e4;
  width: 100%;
  margin-top: 20px;
}
.footerLinks {
  color: black;
  font-family: Roboto Slab;
}
.borderLinksContent {
  font-size: 20px;
  font-weight: 500;
}
a.link {
  color: black;
}
.spacing {
  margin-top: 28px;
}
.infoColumn {
  display: flex;
}
.searchList-title {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 161.5%;
  color: #1d1d1d;
}
.searchList-secondryText {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 161.5%;
  color: #757575;
}
.student {
  background: rgba(0, 133, 255, 0.12) !important;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 161.5%;
  color: #0085ff !important;
  text-transform: capitalize;
}
.teacher {
  background: rgba(56, 167, 55, 0.12) !important;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 161.5%;
  color: #38a737 !important;
  text-transform: capitalize;
}
.school {
  background: rgba(45, 45, 45, 0.12) !important;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 161.5%;
  color: #0085ff !important;
  text-transform: capitalize;
}
.appBarBottomImg {
  margin-top: 110px;
  margin-bottom: 10px;
}
.HarlemFooterImage {
  width: 250px;
}
.switchStudentPage-btn {
  border: 0.5px dashed #ffffff;
  border-radius: 8px;
  background: #341d75;
}
.switchStudentPage-btn span {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 215%;
  color: #ffffff;
  letter-spacing: 0px;
  text-transform: none;
}
.logout-btn span {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 161.5%;
  color: #ffffff;
  letter-spacing: 0px;
  text-transform: none;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  * {
    /* overflow-x: hidden; */
  }
  .footerIcon {
    width: 30px;
    margin-left: 20px;
  }
  .HarlemFooterImage {
    width: 190px;
  }
  .footerText {
    margin-top: 10px;
    font-size: 15px;
  }
  .borderLinksContent {
    font-size: 16px;
  }
  .contactImg {
    min-width: 40px;
  }
  .appBarTitle {
    font-size: 18px;
    padding-top: 15px;
  }
  .v-btn >>> span {
    color: #2c1963;
    font-weight: 700;
    font-size: 14px;
    font-family: Roboto Slab;
  }
  .appBarBottomImg {
    margin-top: 145px;
    margin-bottom: -10px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
}
</style>
